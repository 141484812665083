import React, { FunctionComponent } from 'react';
import FaqRow from "./FaqRow";

type FaqsProps = {
    items: any[],
};

const Faqs: FunctionComponent<FaqsProps> = ({ items, ...props }) => {

    return (
        <div className="max-w-2xl divide-y-2 divide-ink-10">
            <dl className="space-y-6 divide-y divide-ink-10">
                {items.map(({ node: { data: data } }, index) => (
                    <FaqRow
                        key={`faq-row-${index}`}
                        title={data.title}
                        content={data.content}
                    />
                ))}
            </dl>
        </div>
    );
};

export default Faqs;
