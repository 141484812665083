import React, { FunctionComponent, useEffect, useState } from 'react';
import Section from "../components/Section";
import Hero from "../components/Hero";
import FaqImage from "../assets/images/faqs.png";
import CategorizedFaqsList from "../components/Faqs/CategorizedFaqsList";
import useTranslations from '../components/useTranslations';
import { graphql } from 'gatsby';

type FaqsProps = {
    data?: any
};

const FaqsPage: FunctionComponent<FaqsProps> = (props) => {
    const { pages: { faqs } } = useTranslations()
    const [categories, setCategories] = useState([])
    const [faqsItems, setFaqsItems] = useState({})

    useEffect(() => {
        if (props.data.faqsCategories.nodes[0].data.categories) {
            setCategories(props.data.faqsCategories.nodes[0].data.categories)
        }
        if (props.data.faqs.edges) {
            let items = {}

            props.data.faqs.edges.map(({ node: { data: data } }) => {

                if (!items[data.category]) {
                    items[data.category] = []
                }
                items[data.category].push({
                    node: {
                        data: data
                    }
                })
            })
            setFaqsItems(items)
        }
    }, [])


    return (
        <>
            <Section className='py-8' fullWidth>
                <div>
                    <Hero
                        headline={faqs.title}
                        heroImage={FaqImage}
                        outerContainer
                        isFaq={true}
                    />
                </div>
            </Section>
            <Section className={'py-12'}>
                <div className={''}>
                    <CategorizedFaqsList categories={categories} items={faqsItems} />
                </div>
            </Section>
        </>
    );
};


export const query = graphql`
    query FaqsPage($locale: String){
        faqsCategories: allFaqsCategoryNode(filter: {locale: {eq: $locale}}) {
            nodes {
                data {
                    categories {
                        name
                    }
                }
                locale
            }
        }

        faqs: allFaqsNode(filter: {locale: {eq: $locale}}) {
            edges {
                node {
                    data {
                        category
                        content
                        title
                    }
                    locale
                }
            }
        }
    }
`

export default FaqsPage;
