import React, { FunctionComponent, useEffect, useState } from 'react';
import Faqs from "./index";
import Heading from "../Heading";

type CategorizedFaqsListProps = {
    items: {},
    categories: any[],
};

const CategorizedFaqsList: FunctionComponent<CategorizedFaqsListProps> = ({ categories, items, ...props }) => {
    const [selected, setSelected] = useState<any[]>([])
    const [selectedCategory, setSelectedCategory] = useState(undefined)

    const handleChange = (e) => {
        if (e.target.value) {
            setSelectedCategory(e.target.value)
        }
    }

    useEffect(() => {
        if (!(selectedCategory && items[selectedCategory])) {
            setSelected([])
            return
        }

        setSelected(items[selectedCategory])
    }, [selectedCategory])

    return (
        <>
            <div className={'flex justify-center w-full'}>
                <div className={'w-2/3'}>
                    <Heading type='h5' className={'font-semibold mb-4'}>Select category</Heading>
                    <select name="" id="" value={selectedCategory} onChange={handleChange}>
                        {!selectedCategory && <option value="">Please select category</option>}
                        {
                            categories.map((item) => <option key={`categoryFaqListOption-${item.name}`} value={item.name}>{item.name}</option>)
                        }
                    </select>
                    <div className={'py-12'}>
                        <Faqs items={selected} />
                    </div>
                </div>
            </div>

        </>
    );
};

export default CategorizedFaqsList;
