import React, { FunctionComponent } from 'react';
import Button from "./Button/Button";
import classNames from "classnames";

type Props = {
    headline: string,
    subheadline?: string,
    heroImage?: any,
    videoUrl?: string,
    buttons: any,
    button1?: any,
    button2?: any,
    outerContainer?: boolean,
    isFaq: boolean
};

const Hero: FunctionComponent<Props> = ({ headline, subheadline, heroImage, buttons, outerContainer= false, isFaq = false }) => {
    const outerPadding = isFaq ? 'py-36' : heroImage ? 'py-32' : ''

    return (
        <div className={outerContainer ? 'container' : ''}>
            <div className={`${outerPadding} mobile-bg-none bg-no-repeat bg-right `} style={{backgroundImage: `url(${heroImage})`}}>
                <div className={outerContainer ? '' : 'container'}>
                    <div className={classNames('w-full lg:w-3/5 ', heroImage ? '' : 'mx-auto text-center')}>
                        <div className={''}>
                            <h1 className={'text-h1 font-bold font-sans mb-4'}>{headline}</h1>
                            { subheadline ? (<h4 className={heroImage ? 'text-h4     w-2/3 mb-8 font-sans' : 'text-h4 text-center font-sans'}>{subheadline}</h4>) : null }
                            { buttons ? (buttons) : null}
                        </div>
                        {
                            heroImage ? (
                                <div className={'lg:hidden w-full'}>
                                    <img src={heroImage} alt="" className={'w-full'}/>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Hero;
